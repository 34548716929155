import React, {
  Component
} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native';

import {RestaurantCss} from "./styles/restaurant.css";
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import Moment from 'moment';

export default class TableListItem extends Component {
  render() {
    // We are going to return a simple list item with just a title for now
    // this.props.setModalVisible(this.props.restaurant._key, true)
    return (
      <View style={[RestaurantCss.listItem,]}>
        <View style={[RestaurantCss.rowContainerLF]}>
          <View style={RestaurantCss.headingLeft}>
            <TouchableOpacity
              onPress={() => this.props.toogleNamePhone(this.props.table.key, this.props.isBooked)}>
              <Text style={{fontWeight:'500', color: 'grey', fontSize: 12.6}}>
                {this.props.table.people} people
              </Text>
            </TouchableOpacity>
          </View>
          {this.props.isBooked && <View style={{flexDirection: 'row', flex: 1, justifyContent: 'center'}}>
            <TouchableOpacity
              onPress={() => this.props.toogleNamePhone(this.props.table.key, this.props.isBooked)}>
              <Text style={{fontWeight:'500', color: 'grey', fontSize: 12.6}}>
                {this.props.table.shouldShowName === 1 ? this.props.table.bookedBy : (this.props.table.shouldShowName === 2 ? this.props.table.bookedByPhone : this.props.table.bookedByEmail)}
              </Text>
            </TouchableOpacity>
          </View>}
          <View style={{flexDirection: 'row', flex: 1, justifyContent: this.props.isBooked ? 'flex-end' : 'center'}}>
            <TouchableOpacity
              onPress={() => this.props.toogleNamePhone(this.props.table.key, this.props.isBooked)}>
              <Text style={{fontWeight:'500', color: 'grey', fontSize: 12.6}}>
                {Moment(this.props.table.startTime).format('HH:mm')}-{Moment(this.props.table.endTime).format('HH:mm')}
              </Text>
            </TouchableOpacity>
          </View>
          {!this.props.isBooked && <TouchableOpacity
            style={RestaurantCss.headingRight}
            onPress={() => this.props.deleteTable(this.props.table.key)}>
            <View>
              <Icon
                size={20}
                name='trash'
                color={'grey'}/>
              </View>
          </TouchableOpacity>}
        </View>
      </View>
    );
  }
}

