import React, {
  Component
} from 'react';
import {
  View,
  Text,
} from 'react-native';

import {RestaurantCss} from "./styles/restaurant.css";
import Moment from 'moment';

export default class TableTypeListItem extends Component {
  render() {
    // We are going to return a simple list item with just a title for now
    // this.props.setModalVisible(this.props.restaurant._key, true)
    return (
      <View key={this.props.tableType._key} style={RestaurantCss.listItem}>
        <View style={[RestaurantCss.rowContainerLF,{marginLeft:10,marginRight:10}]}>
          <View style={RestaurantCss.headingLeft}>
            <Text style={{color: '#023e4eff', fontSize: 14,marginTop:5}}>
              Table for {this.props.tableType.tableType} people - wish to arrive between {Moment(parseInt(this.props.tableType.startTime)).format('HH:mm')}-{Moment(parseInt(this.props.tableType.endTime)).format('HH:mm')}
            </Text>
          </View>
          {/*<View style={{flexDirection: 'row', flex: 1, justifyContent: 'flex-end',marginTop:10}}>
            <Text style={{color: '#023e4eff', fontSize: 14  }}>
              {this.props.tableType.noOfUsers} user
            </Text>
          </View>*/}
        </View>
      </View>
    );
  }
}

