/**
 * @class Database
 */

import Moment from 'moment';
import * as firebase from "firebase";

export default class Database {
    static isDataAdded = false;

    /**
     * Sets a users mobile number
     * @param userId
     * @param mobile
     * @returns {firebase.Promise<any>|!firebase.Promise.<void>}
     */
    static setUserMobile(userId, mobile) {
        const userMobilePath = "/users/" + userId;

        return firebase.database().ref(userMobilePath).set({
            mobile: mobile
        })
    }

    /**
     * Listen for changes to a users mobile number
     * @param userId
     * @param callback Users mobile number
     */
    static listenUserMobile(userId, callback) {
        const userMobilePath = "/users/" + userId;

        firebase.database().ref(userMobilePath).on('value', (snapshot) => {
            var mobile = "";
            if (snapshot.val()) {
                mobile = snapshot.val().mobile
            }

            callback(mobile)
        });
    }

    static setRestaurantFullyBooked(userId, restaurantKey, isFullyBooked){
      const userMobilePath = "/restaurants/" + userId + "/" + restaurantKey;

      firebase.database().ref(userMobilePath).update({
          fully_booked: isFullyBooked
      });
    }

    static setIsOnEmailNotification(userId, restaurantKey, isOnEmailNotification, email){
      const userMobilePath = "/restaurants/" + userId + "/" + restaurantKey;

      firebase.database().ref(userMobilePath).update({
          isOnEmailNotification: isOnEmailNotification,
          email: email
      });
    }

    static setIsOnAppNotification(userId, restaurantKey, isOnAppNotification){
      const userMobilePath = "/restaurants/" + userId + "/" + restaurantKey;

      firebase.database().ref(userMobilePath).update({
          isOnAppNotification: isOnAppNotification
      });
    }

    static setIsOpenTableForDinner(userId, restaurantKey, isOpenTable){
      const userMobilePath = "/restaurants/" + userId + "/" + restaurantKey;

      firebase.database().ref(userMobilePath).update({
        isOpenTableForDinner: isOpenTable,
      });
    }

    static setIsOpenTableForLunch(userId, restaurantKey, isOpenTable){
      const userMobilePath = "/restaurants/" + userId + "/" + restaurantKey;

      firebase.database().ref(userMobilePath).update({
        isOpenTableForLunch: isOpenTable,
      });
    }

    static openTable(userId, restaurantKey, options) {
      let path = "/restaurants/" + userId + "/" + restaurantKey;
      firebase.database().ref(path).update(options);
    }

    static publishTable(table, callback){
      const userMobilePath = `/tables/${table.restaurantKey}`;
      firebase.database().ref(userMobilePath).push(table);

      Database.logEvent({
        _key: table.restaurantKey,
        name: table.restaurantName,
      }, false, true);

      callback("Published");
    }

    static bookTable(restaurant, userId, tableKey, callback){
      const userMobilePath = `/tables/${restaurant._key}/${tableKey}`;
      firebase.database().ref(userMobilePath).transaction((table) => {
        if(table){
          var curTime = new Date().getTime();
          // debugger
          if(table.bookedBy){
            return;
          }else if(curTime < table.endTime) {
            table.bookedBy = userId;
            table.isBooked = true;
            table.searchKey = table.restaurantKey + "_1";

            return table;
          }else{
            return;
          }
        }else{
          return;
        }
      }, (error, committed, snapshot) => {
        if (error) {
          console.log('Something went wrong', error);
          callback(false);
        } else if (!committed) {
          console.log('Aborted'); // Returning undefined will trigger this
          callback(false);
        } else {
          console.log('Table booked');
          Database.logEvent(restaurant, false);
          callback(true);
        }

        console.log('User table now: ', snapshot.val());
      });
    }

    static logEvent(restaurant, isClicked, isTablePublished) {
      let logKey = Moment(new Date()).format('YYYY-MM-DD');
      let userlogPath = "/logs/" + restaurant._key + '-' + logKey;

      firebase.database().ref(userlogPath).transaction((log) => {
        if(log){
          if (isClicked) {
            if (log.clickedCount) {
              log.clickedCount += 1;
            } else {
              log.clickedCount = 1;
            }
          } else if (isTablePublished) {
            if (log.publishedTables) {
              log.publishedTables += 1;
            } else {
              log.publishedTables = 1;
            }
          } else {
            if (log.bookingCount) {
              log.bookingCount += 1;
            } else {
              log.bookingCount = 1;
            }
          }
          return log;
        }else{
          if(isClicked) {
            return {
              date: logKey,
              restaurantName: restaurant.name,
              clickedCount: 1,
              publishedTables: 0,
              bookingCount: 0
            };
          } else if(isTablePublished) {
            return {
              date: logKey,
              restaurantName: restaurant.name,
              clickedCount: 0,
              publishedTables: 1,
              bookingCount: 0
            };
          } else {
            return {
              date: logKey,
              restaurantName: restaurant.name,
              clickedCount: 0,
              publishedTables: 0,
              bookingCount: 1
            };
          }
        }
      }, (error, committed, snapshot) => {
        if (error) {
          console.log('Something went wrong', error);
        } else if (!committed) {
          console.log('Aborted'); // Returning undefined will trigger this
        } else {
          console.log('Table booked');
        }

        console.log('User table now: ', snapshot.val());
      });
    }

    static deleteTable(tableKey, restaurantKey, callback){
      let userMobilePath = `/tables/${restaurantKey}/${tableKey}`;
      firebase.database().ref(userMobilePath).remove();
      callback('deleted');
    }

    static setUserNotiSetting(userId, notiOn){
      let userMobilePath = "/users/" + userId;

      firebase.database().ref(userMobilePath).update({
          notiOn: notiOn
      });
    }

    static setUserData(userId, pax, mobile,startTime,endTime){
      return new Promise((resolve) => {
        let userMobilePath = "/users/" + userId;
        return firebase.database().ref(userMobilePath).update({
            pax: pax,
            phone_number: mobile,
            UserNotifStartTime:startTime,
            UserNotifEndTime:endTime
        }).then((val)=>{
          resolve(val)
        });
      });
    }

    static setNotiId(userId, token){
      let userMobilePath = "/users/" + userId;

      firebase.database().ref(userMobilePath).update({
          notificationId: token
      });
    }

    /**
     * Listen for changes to a users mobile number
     * @param userId
     * @param callback Users mobile number
     */
    static listenUserNotiSetting(userId, callback) {
        let userMobilePath = "/users/" + userId;

        firebase.database().ref(userMobilePath).once('value', (snapshot) => {
            var notiOn = false;
            if (snapshot.val()) {
                notiOn = snapshot.val().notiOn
            }

            callback(notiOn)
        });
    }

    static setUserRestaurantNotiSetting(restaurantId, userId, notiOn){
      let userMobilePath = "/users/" + userId + "/restaurants_noti/" + restaurantId;

      firebase.database().ref(userMobilePath).set({
          notiOn: notiOn
      });
    }

    /**
     * Listen for changes to a users mobile number
     * @param userId
     * @param callback Users mobile number
     */
    static listenUser(userId, callback) {
        let userMobilePath = "/users/" + userId;

        firebase.database().ref(userMobilePath).on('value', (snapshot) => {
          console.log("listenUser", 'called');
            callback(snapshot)
        });
    }

    static listenUserStop(userId) {
        let userMobilePath = "/users/" + userId;
        firebase.database().ref(userMobilePath).off();
    }

    /**
     * Listen for changes to a users mobile number
     * @param userId
     * @param callback Users mobile number
     */
    static listenUserRestaurantNotiSetting(userId, callback) {
        let userMobilePath = "/users/" + userId + "/restaurants_noti";

        firebase.database().ref(userMobilePath).on('value', (snapshot) => {
          firebase.database().ref(userMobilePath).off('value');
            callback(snapshot)
        });
    }
    static unListenUserRestaurantNotiSetting(userId){
      let userMobilePath = "/users/" + userId + "/restaurants_noti";
      firebase.database().ref(userMobilePath).off();
    }

    static setUserFavourites(restaurantId, userId, isFavourite){
      let userMobilePath = "/users/" + userId + "/favourite_restaurants/" + restaurantId;
      try{
        if(isFavourite){
          firebase.database().ref(userMobilePath).set({isFavourite: isFavourite});
        }else{
          firebase.database().ref(userMobilePath).remove();
        }
      } catch (error) {
          console.log(error);
      }
    }

    /**
     * Listen for changes to a users mobile number
     * @param userId
     * @param callback Users mobile number
     */
    static listenUserFavourites(userId, callback) {
        let userMobilePath = "/users/" + userId + "/favourite_restaurants";

        firebase.database().ref(userMobilePath).on('value', (snapshot) => {
          firebase.database().ref(userMobilePath).off('value');
            callback(snapshot)
        });
    }
    static unListenUserFavourites(userId){
      let userMobilePath = "/users/" + userId + "/favourite_restaurants";

      firebase.database().ref(userMobilePath).off();
    }

    static editRestaurant(restaurant, callback){
      console.log(restaurant,"database");
      let restaurantPath = "/restaurants/" + restaurant._uid + "/" + restaurant._key;
      let ref = firebase.database().ref(restaurantPath);
      var images = restaurant.images.slice();
      delete restaurant.images;
      delete restaurant._uid;
      delete restaurant._key;
      ref.set(restaurant);
      restaurantPath = restaurantPath + "/images";
      ref = firebase.database().ref(restaurantPath);
      for (var i = 0; i < images.length; i++) {
        var img = images[i];
        var storageId = img.storageId;
        delete img.storageId;
        delete img.uid;
        delete img.restaurantId;
        ref.child(storageId).set(img);
      }
      callback("Restaurant Updated")
    }

    static deleteRestaurant(restaurant, callback){
      let restaurantPath = "/restaurants/" + restaurant._uid;

      let ref = firebase.database().ref(restaurantPath);
      ref.remove();
      callback('deleted');
    }

    static addRestaurantImage(restaurantId, imageData){
        let restaurantImagesPath = "/restaurant_images/" + restaurantId;
        firebase.database().ref(restaurantImagesPath).push(imageData);
    }
    static deleteRestaurantImage(restaurantId, imageId, imageData){
        let restaurantImagesPath = "/restaurant_images/" + restaurantId;
        firebase.database().ref(restaurantImagesPath).push(imageData);
    }
}
