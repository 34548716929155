import * as firebase from "firebase";

export default class Firebase {
	/**
	 * Initialises Firebase
	 */
	static initialise() {
		if(!firebase.apps.length){
			firebase.initializeApp({
				apiKey: "AIzaSyCWZr3dQFxeY5dCfWtnwf5_jB1CauLiUuQ",
				authDomain: "firstserved-f859e.firebaseapp.com",
				databaseURL: "https://firstserved-f859e.firebaseio.com",
				storageBucket: "firstserved-f859e.appspot.com"
			});
		}
	}

}
