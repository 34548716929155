/**
 * @class Login
 */

import {
  TextInput,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  NetInfo,
  Platform,
  Switch,
  Dimensions,
  Alert,
} from "react-native";
import React, {Component} from "react";
import * as firebase from "firebase";
import Firebase from "./firebase/firebase";
import { HEXCOLOR } from "./styles/hexcolor.js";
import Modal from 'react-native-web-modal';
import Popup from 'react-popup';

import {RestaurantCss} from "./styles/restaurant.css";
import TableListItem from "./table_list_item";
import Database from "./firebase/web_database";
import {TextInputMask} from 'react-native-masked-text';
import Moment from 'moment';
import TableTypeListItem from "./table_type_item_list";
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import IIcon from 'react-native-vector-icons/dist/Ionicons';
import iconFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import iconFont1 from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import { CheckBox } from 'react-native-elements';
const ls = require('react-native-local-storage');
const screenWidth = Dimensions.get('window').width;
const OPEN_FOR_LUNCH = 1;
const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: FontAwesome;
}`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

const iconFontStyles1 = `@font-face {
  src: url(${iconFont1});
  font-family: Ionicons;
}`;
const style1 = document.createElement('style');
style1.type = 'text/css';
if (style1.styleSheet) {
  style1.styleSheet.cssText = iconFontStyles1;
} else {
  style1.appendChild(document.createTextNode(iconFontStyles1));
}

// Inject stylesheet
document.head.appendChild(style);
document.head.appendChild(style1);

class EmailLogin extends Component {
  constructor(props) {
    super(props);

    Firebase.initialise();

    this.state = {
      email: "",
      password: "",
      response: "",
      isLoggedIn: false,
      process: false,
      screenNo: 0,

      userId: '0',
      restaurant: {},
      restaurantKey: '',
      progress: true,
      watchOnTables: false,
      watchOnWaitingList: false,
      waitingListCount: 0,
      isModalVisible: false,
      waitingTables: [],
      availableTables: [],
      bookedTables: [],
      bookedTablesKeys: [],
      isAfterLoad: false,
      curTime: Moment(new Date()).format('DD-MM-YYYY'),
      isOnline: false,
      isOpenTableForDinner: false,
      isOpenTableForLunch: false,
      isOnAppNotification: true,
      isOnEmailNotification: true,
      isFullyBooked: true,

      people: 2,
      isDateTimePickerVisible: false,
      txtStartTime: '',
      txtEndTime: '',
      startTime: 'Set start time',
      endTime: 'Set end time',

      isBookingOptionsModalVisible: false,
      isBookingOptionsModalVisibleForLunch: false,
      isBookingOptionsModalVisibleForDinner: false
    };
  }

  logout = () => {
    try {
      const th = this;
      firebase.auth().signOut()
      .then(function(){
        th.setState({screenNo: 0});
        ls.default.clear();
        th.restaurantRef.off();
        th.ref.off();
        th.userRef.off();
        th.unmountNetworkListner();
        th.setState({isLoggedIn: false});
        window.location.reload();
      }).catch(function(error){
        console.log("Error in logout.");
      });
    } catch (error) {
      console.log(error);
    }
  }

  componentWillMount(){
    NetInfo.isConnected.fetch().then(isConnected => {
      console.log('First, is ' + (isConnected ? 'online' : 'offline'));
      this.setState({isOnline: isConnected});
    });

    NetInfo.isConnected.addEventListener(
      'connectionChange',
      this.handleFirstConnectivityChange
    );

    const th = this;
    console.log("LocalStorage: ", ls.default.get, " >>>>>>>>>>>>>>.. ", ls.get);
    ls.default.get("uid").then(function(data){
      console.log(data);
      if(data){
        th.setState({screenNo: 1, userId: data});
        th.addListener();
      }
    })
  }

  addListener = () => {
    console.log("componentWillMount restaurant home.");
    this.restaurantRef = firebase.database().ref("/restaurants/" + this.state.userId);
    this.userRef = firebase.database().ref("/users");

    this.restaurantRef.on('value', (dataSnapshot) => {
      let restaurant = {};
      dataSnapshot.forEach((ch) => {
        const rest = ch.val();
        restaurant = {
          name: (rest.name ? rest.name : ''),
          type: (rest.type ? rest.type : ''),
          phone_number: (rest.phone_number ? rest.phone_number : ''),
          short_description: (rest.short_description ? rest.short_description : ''),
          long_description: (rest.long_description ? rest.long_description : ''),
          booking_message: (rest.booking_message ? rest.booking_message : ''),
          address: (rest.address ? rest.address : ''),
          website_url: (rest.website_url ? rest.website_url : ''),
          booking_url: (rest.booking_url ? rest.booking_url : ''),
          instagram_url: (rest.instagram_url ? rest.instagram_url : ''),
          fully_booked: rest.fully_booked,
          email: (Boolean(rest.email) ? rest.email : ''),
          isOpenTableForDinner: rest.isOpenTableForDinner,
          isOpenTableForLunch: rest.isOpenTableForLunch,
          isOnEmailNotification: rest.isOnEmailNotification,
          isOnAppNotification: rest.isOnAppNotification,
          filters: rest.filters,
          _uid: dataSnapshot.key,
          _key: ch.key
        };
      });

      var state = {
        restaurant: restaurant,
        restaurantKey: restaurant._key,
        progress: false,
        isFullyBooked: restaurant.fully_booked,
        isOpenTableForDinner: restaurant.isOpenTableForDinner,
        isOpenTableForLunch: restaurant.isOpenTableForLunch,
        isOnEmailNotification: restaurant.isOnEmailNotification,
        isOnAppNotification: restaurant.isOnAppNotification,
        bookingOptionsError: false,
        byBookingSystem: false,
        byPhone: false,
        byWalkIn: false  
      };

      if(Boolean(restaurant.email)) state.email = restaurant.email;


      this.setState(state);

      const th = this;

      if(!this.state.watchOnWaitingList){
        this.userRef.on("value", function(snapshot) {
          var users = snapshot.val();
          th.setState({users: users});
        });

        this.userRef.orderByChild("restaurants_noti/" + restaurant._key + "/notiOn").equalTo(true).on("value", function(snapshot) {
          var waitingListCount = 0;
          var users = snapshot.val();
          var tableTypes = {};
          var tableTypes1 = [];
          if(users){
            var keys = Object.keys(users);
            for(var i = 0; i < keys.length; i++){
              var u = users[keys[i]];
              if(u.restaurants_noti) {
                var notiKeys = Object.keys(u.restaurants_noti);
                var chk = false;
                for(var j = 0; j < notiKeys.length; j++){
                  if(u.restaurants_noti[notiKeys[j]].notiOn){
                    chk = true;
                    break;
                  }
                }
                if(chk && Boolean(u.pax) && Boolean(u.UserNotifStartTime) && Boolean(u.UserNotifEndTime)){
                  waitingListCount++;
                  var key = `${u.pax}_${u.UserNotifStartTime}_${u.UserNotifEndTime}`;
                  
                  if(tableTypes[key]){
                    tableTypes[key].count += 1;
                  }else{
                    tableTypes[key] = {count: 1, pax: u.pax, startTime: u.UserNotifStartTime,
                      endTime: u.UserNotifEndTime};
                  }
                  if(u.notificationId){
                    // waitingListCount++;
                  }
                }
              }
            }
            keys = Object.keys(tableTypes);
            for(i = 0; i < keys.length; i++){
              tableTypes1.push({
                key: keys[i],
                tableType: tableTypes[keys[i]].pax,
                noOfUsers: tableTypes[keys[i]].count, 
                startTime: tableTypes[keys[i]].startTime,
                endTime: tableTypes[keys[i]].endTime
              })
            }
          }
          console.log("waitingListCount", waitingListCount);
          th.setState({
            waitingTables: tableTypes1,
            waitingListCount: waitingListCount
          });
        });
        this.setState({watchOnWaitingList: true});
      }

      this.ref = firebase.database().ref(`/tables/${restaurant._key}`);

      if (!this.state.watchOnTables) {
        this.ref.orderByChild("startTime").startAt(new Date().getTime()).on("value", function(snapshot) {
          var aTables = [];
          var bTables = [];
          var bTablesKeys = [];
          var bookedTableCount = 0;
          var curTime = new Date().getTime();

          console.log("curTime", new Date());

          snapshot.forEach((ch) => {
            if (curTime < ch.val().endTime) {
              if (ch.val().isBooked){
                console.log(ch.val().bookedBy, th.state.users);
                bTablesKeys.push(ch.key);
                if (th.state.bookedTablesKeys.indexOf(ch.key) === -1) {
                  bookedTableCount += 1;
                }
                bTables.push({
                  restaurantKey: ch.val().restaurantKey,
                  startTime: ch.val().startTime,
                  endTime: ch.val().endTime,
                  people: ch.val().pax,
                  shouldShowName: 1,
                  bookedBy: th.state.users[ch.val().bookedBy].name,
                  bookedByPhone: th.state.users[ch.val().bookedBy].phone_number,
                  bookedByEmail: th.state.users[ch.val().bookedBy].email,
                  key: ch.key
                });
              } else {
                aTables.push({
                  restaurantKey: ch.val().restaurantKey,
                  startTime: ch.val().startTime,
                  endTime: ch.val().endTime,
                  people: ch.val().pax,
                  key: ch.key
                });
              }
            }
          });
          if (th.state.isAfterLoad && bookedTableCount > 0){
            th.nonPersistentNotification(bookedTableCount + " tables booked.");
          }
          th.setState({
            isAfterLoad: true,
            bookedTables: bTables,
            bookedTablesKeys: bTablesKeys,
            availableTables: aTables,
          });
        });
        this.setState({watchOnTables: true});
      }
    });
  }

  playSound(){   
    document.getElementById("sound").innerHTML='<audio autoplay="autoplay"><source src="/jingle-bells-sms.mp3" type="audio/mpeg" /><source src="/jingle-bells-sms.ogg" type="audio/ogg" /><embed hidden="true" autostart="true" loop="false" src="jingle-bells-sms.mp3" /></audio>';
  }

  nonPersistentNotification = (_msg) => {
    if (!('Notification' in window)) {
      console.log('Notification API not supported!');
      return;
    }
    
    try {
      this.playSound();
    } catch (err) {
      console.log('Notification API error: ' + err);
    }
  }

  requestPermission() {
    if (!('Notification' in window)) {
      console.log('Notification API not supported!');
      return;
    }
    if (Notification.permission === 'default') {
      Notification.requestPermission(function (result) {
        console.log(result);
      });
    }
  }

  componentDidMount() {
    this.timeoutHandle = setTimeout(()=>{
      this.setState({ curTime: Moment(new Date()).format('MMM DD YYYY') })
    }, 60000);
    this.requestPermission();
  }

  unmountNetworkListner = () => {
    NetInfo.isConnected.removeEventListener(
      'connectionChange',
      this.handleFirstConnectivityChange
    );
  }

  handleFirstConnectivityChange = (isConnected) => {
    console.log('Then, from listener is ' + (isConnected ? 'online' : 'offline'));
    this.setState({isOnline: isConnected});
  }

  openSettings = () => {
    this.setState({screenNo: 2})
  }

  openPublish = () => {
    this.setState({screenNo: 3, people: 2, txtStartTime: '', txtEndTime: '', startTime: "Set start time", endTime: "Set end time"})
  }

  setModalVisible = (value) => {
    this.setState({isModalVisible: value});
  }

  login = () => {
    this.setState({process:true})
    const th = this;
    try {
      this.unsubscribe = firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          if(th.state.isLoggedIn) return;
          th.setState({isLoggedIn: true});
          let userMobilePath = "/users/" + user.uid;
          let restaurantPath = "/restaurants/" + user.uid;
          let userP = firebase.database().ref(userMobilePath).once('value');
          let restP = firebase.database().ref(restaurantPath).once('value');
          Promise.all([userP, restP]).then(results => {
            let usr = results[0].val();
            let rest = results[1].val();
            if(rest){
              let keys = Object.keys(rest);
              let key = keys[0];
              rest = rest[key];
            }

            if (usr && usr.isAdmin) {
              alert("For browser admin is not allowed.");
              firebase.auth().signOut().then(function() {
                ls.default.clear("name", "Hello");
                console.log("Sign-out successful.");
              }, function(error) {
                console.log("An error happened.");
              });
              return;
            }else if (usr && usr.isRestaurantAdmin) {
              ls.default.save("userType", 'restaurant');
              ls.default.save("uid", user.uid);
              ls.default.save("email", user.email);
              th.setState({screenNo: 1, userId: user.uid});
              th.addListener();
              th.setState({process: false});
            }

            if (th.unsubscribe && th.unsubscribe()) {
              th.unsubscribe = undefined;
            }
          });
        }
      });

      firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then(function(){
        th.setState({response: "Logged In!"});
      }).catch(function(error) {
        // Handle Errors here.
        var errorMessage = error.message;
        th.setState({process: false, response: errorMessage})
        // ...
      });
    } catch (error) {
        this.setState({process: false, response: 'Some Exception'})
    }
  }

  componentWillUnmount(){
    if(this.unsubscribe && this.unsubscribe()){
      this.unsubscribe = undefined;
    }
    console.log('componentWillUnmount restaurant home');
    clearTimeout(this.timeoutHandle);
    this.restaurantRef.off();
    this.ref.off();
    this.userRef.off();
    this.unmountNetworkListner();
  }

  render() {
    const {
      isBookingOptionsModalVisible,
      isBookingOptionsModalVisibleForLunch,
      isBookingOptionsModalVisibleForDinner,
      restaurant
    } = this.state;

    if(this.state.screenNo === 0){
      return (
        <View style={[RestaurantCss.container, {alignItems: "center"}]}>
          {
            !this.state.process ? <View style={[{flex: 1}]}>
              <View style={[RestaurantCss.navBar]}>
                <View style={RestaurantCss.leftContainer}>

                </View>
                <Text style={[RestaurantCss.navtext, {fontWeight:'bold',marginLeft:30,fontSize: 13,marginTop:(Platform.OS === 'ios') ? 5 : 0, color: 'white'}]}>{"RESTAURANT LOGIN "}</Text>
                <View style={[RestaurantCss.rightContainer, {paddingRight: 15}]}>
                  
                </View>
              </View>
              <View style={[styles.formGroup]}>
                <Text style={[RestaurantCss.shortDescription]}>Email Address</Text>
                <TextInput
                  style={[RestaurantCss.underlineRectangle, {backgroundColor: '#FFF', color: HEXCOLOR.lightBrown, fontSize: 18}]}
                  onChangeText={(email) => this.setState({email})}
                  value={this.state.email}
                  keyboardType="email-address"/>
                <View style={{margin:8}}/>
                <Text style={[RestaurantCss.shortDescription]}>Password</Text>
                <TextInput
                  style={[RestaurantCss.underlineRectangle, {backgroundColor: '#FFF', color: HEXCOLOR.lightBrown, fontSize: 18}]}
                  onChangeText={(password) => this.setState({password})}
                  value={this.state.password}
                  secureTextEntry={true}/>

                <View style={[styles.submit,{marginTop:30,flexDirection:'row',justifyContent:'center', alignItems:'center'}]}>
                  <TouchableOpacity
                    style={[RestaurantCss.publish, {alignItems:'center',width:155,backgroundColor:'#023e4eff',borderRadius:0}]}
                    onPress={() => this.login()}>
                      <Text style={[RestaurantCss.publishText,{color:'white'}]}>Login</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            :
            <View style={{flex:1,flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
              <Text>Loading...</Text>
            </View>
          }
        </View>
      );
    } else if (this.state.screenNo === 1) {
      if(!this.state.isOnline){
        return (
          <View style={[RestaurantCss.container, {padding: 10}]}>
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
              <Text style={[RestaurantCss.headerText, {color: '#023e4eff'}]}>We can’t seem to connect to the First Served network. Please check your internet connection.</Text>
            </View>
          </View>
        );
      }else if(this.state.progress){
        return(
          <View style={RestaurantCss.container}>
            <View style={[RestaurantCss.rowContainer, {paddingTop: 25}]}>
              <Text>Loading...</Text>
            </View>
          </View>
        )
      }else{
        return (
          <View style={[RestaurantCss.container,{padding:20,paddingTop:0}]}>
            <Modal
              animationType="slide"
              transparent={false}
              visible={this.state.isModalVisible}
              onRequestClose={() => {this.setModalVisible(false)}}>

              <View style={{flex: 1, backgroundColor:  HEXCOLOR.pureWhite}}>
                <View style={RestaurantCss.navBar}>
                  <View style={RestaurantCss.leftContainer}>

                  </View>
                  <Text style={[RestaurantCss.navtext, {fontWeight:'bold',marginLeft:30,fontSize: 13,marginTop:(Platform.OS === 'ios') ? 5 : 0, color: 'white'}]}>{this.state.restaurant.name ? this.state.restaurant.name.toUpperCase() : ""}</Text>
                  <View style={[RestaurantCss.rightContainer, {paddingRight: 15}]}>
                    <TouchableOpacity
                      onPress={() => this.setModalVisible(false)}>
                      <View>
                        <IIcon
                          size={42}
                          name='ios-close'
                          color='white'/>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>

                <FlatList
                  data={this.state.waitingTables}
                  enableEmptySections={true}
                  removeClippedSubviews={false}
                  renderItem={this._renderItem}
                  keyExtractor={item => item.key}
                  style={[RestaurantCss.listView, {marginTop: 30}]}/>
              </View>
            </Modal>
            <View style={RestaurantCss.navBar}>
              <View style={RestaurantCss.leftContainer}>

              </View>
              <Text style={[RestaurantCss.navtext, {fontWeight:'bold',marginLeft:30,fontSize: 13,marginTop:(Platform.OS === 'ios') ? 5 : 0, color: 'white'}]}>{this.state.restaurant.name ? this.state.restaurant.name.toUpperCase() : ""}</Text>
              <View style={[RestaurantCss.rightContainer, {paddingRight: 15}]}>
                <TouchableOpacity
                  onPress={() => this.openSettings()}>
                  <View>
                    <Icon
                      size={21}
                      name='cog'
                      color='white'/>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{flexDirection: 'row'}}>
              <View style={[{flex: 1, marginRight: 5}]}>
                <View style={[RestaurantCss.bottomBorderBrown, {flexDirection: 'row', paddingTop: 10}]}>
                  <View style={RestaurantCss.headingLeft}><Text style={{fontWeight:'500',color: '#023e4eff'}}>Available tables</Text></View>
                  <View style={RestaurantCss.headingRight}><Text style={{fontWeight:'500',color: '#023e4eff'}}>{this.state.curTime}</Text></View>
                </View>
                <View style={[RestaurantCss.rowContainerLF,{marginTop:15}]}>
                  <FlatList
                    data={this.state.availableTables}
                    enableEmptySections={true}
                    renderItem={this.renderTable}
                    keyExtractor={item => item.key}/>
                </View>
              </View>
              {/* <View style={[{flex: 1, marginLeft: 5}]}>
                <View style={[RestaurantCss.bottomBorderBrown, {flexDirection: 'row', paddingTop: 10}]}>
                  <View style={RestaurantCss.headingLeft}><Text style={{fontWeight:'500',color: '#023e4eff'}}>Booked tables</Text></View>
                  <View style={RestaurantCss.headingRight}><Text style={{fontWeight:'500',color: '#023e4eff'}}>{this.state.curTime}</Text></View>
                </View>

                <View style={[RestaurantCss.rowContainerLF,{marginTop:15}]}>
                  <FlatList
                    data={this.state.bookedTables}
                    enableEmptySections={true}
                    renderItem={this.renderBookedTable}
                    keyExtractor={item => item.key}/>
                </View>
              </View> */}
            </View>
            <View style={[RestaurantCss.bottomBorderBrown, {paddingTop: 10}]}>
              <TouchableOpacity
                onPress={() => this.setModalVisible(true)}>
                <Text style={{fontWeight:'500',color: '#023e4eff', fontSize: 14}}>Waiting List</Text>
              </TouchableOpacity>
            </View>
            <View style={[{marginTop:20,marginLeft:7}]}>
              <TouchableOpacity
                onPress={() => this.setModalVisible(true)}>
                <View style={[RestaurantCss.rowContainerLF]}>
                  <Icon
                    size={20}
                    name='cutlery'
                    type='font-awesome'
                    color={'grey'}/>
                  <Text style={{color: 'grey', fontSize: 14, paddingLeft: 10}}>{this.state.waitingListCount} People</Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={{flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop: 50,}}>
              <TouchableOpacity
                style={[RestaurantCss.publish, {width: 155, backgroundColor:'#023e4eff',borderRadius:0}]}
                onPress={() => this.openPublish()}>
                  <Text style={[RestaurantCss.publishText,{color:'white'}]}>Publish Table</Text>
              </TouchableOpacity>
            </View>
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
              <View style={{alignItems: 'center'}}>
                <Text style={{color: '#023e4eff', fontWeight:'500', fontSize: 14}}>
                  WE HAVE OPEN TABLES FOR DINNER
                </Text>
              </View>
              <View style={{marginTop: 20}}>
                <Switch onValueChange={(value) => this.setIsOpenTableForDinner(value)}
                  onTintColor={'#023e4eff'}
                  style={{marginBottom: 10}}
                  thumbColor={HEXCOLOR.pureWhite}
                  tintColor={HEXCOLOR.lightBrown}
                  ios_backgroundColor={HEXCOLOR.lightBrown}
                  value={this.state.isOpenTableForDinner}/>
              </View>
              {(restaurant.filters.OPEN_FOR_LUNCH && restaurant.filters.OPEN_FOR_LUNCH.indexOf(OPEN_FOR_LUNCH) > -1) && <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <View style={{alignItems: 'center'}}>
                  <Text style={{color: '#023e4eff', fontWeight:'500', fontSize: 14}}>
                    WE HAVE OPEN TABLES FOR LUNCH
                  </Text>
                </View>
                <View style={{marginTop: 20}}>
                  <Switch onValueChange={(value) => this.setIsOpenTableForLunch(value)}
                    onTintColor={'#023e4eff'}
                    style={{marginBottom: 10}}
                    thumbColor={HEXCOLOR.pureWhite}
                    tintColor={HEXCOLOR.lightBrown}
                    ios_backgroundColor={HEXCOLOR.lightBrown}
                    value={this.state.isOpenTableForLunch}/>
                </View>
              </View>}
              <Text style={{color: '#023e4eff', marginTop: 15, fontWeight:'400', fontSize: 16, textAlign: 'center'}}>
                {"Enable this to be visible in OPEN TABLES today"}
              </Text>
            </View>
            <Popup/>
            {(isBookingOptionsModalVisibleForDinner || isBookingOptionsModalVisibleForLunch) && this.renderBookingOptions()}
          </View>
        );
      }
    } else if(this.state.screenNo === 2){
      if(!this.state.isOnline){
        return (
          <View style={[RestaurantCss.container, {padding: 10}]}>
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
              <Text style={[RestaurantCss.headerText, {color: '#023e4eff'}]}>We can’t seem to connect to the First Served network. Please check your internet connection.</Text>
            </View>
          </View>
        );
      } else {
        return (
          <View style={[RestaurantCss.container, {padding:20, paddingTop:0}]}>
            <View style={[RestaurantCss.navBar]}>
              <View style={{...RestaurantCss.leftContainer, paddingLeft: 15}}>
                <TouchableOpacity
                  onPress={() => this.setState({screenNo: 1})}>
                  <View>
                    <Icon
                      size={21}
                      name='chevron-left'
                      color='white'/>
                  </View>
                </TouchableOpacity>
              </View>
              <Text style={[RestaurantCss.navtext, {fontWeight:'bold',marginLeft:30,fontSize: 13,marginTop:(Platform.OS === 'ios') ? 5 : 0, color: 'white'}]}>{this.state.restaurant.name ? this.state.restaurant.name.toUpperCase() : ""}</Text>
              <View style={[RestaurantCss.rightContainer, {paddingRight: 15}]}>
              </View>
            </View>
            <View style={{backgroundColor: HEXCOLOR.pureWhite}}>
              <TouchableOpacity
                onPress={() => this.logout()}>
                <View style={[RestaurantCss.rowContainerLF, {paddingTop: 25}]}>
                  <View style={[RestaurantCss.headingRight]}>
                    <Icon
                      size={22}
                      name='sign-out'
                      type='octicon'
                      color={"#023e4eff"}/>
                    <Text style={{color: "#023e4eff", fontSize: 14, paddingLeft: 10}}>Sign out</Text>
                  </View>
                </View>
              </TouchableOpacity>

              <View style={[RestaurantCss.rowContainerLF, {paddingTop: 35, backgroundColor:HEXCOLOR.pureWhite}]}>
                <View style={[RestaurantCss.headingLeft, {padding: 10}]}>
                  <Text style={{color: "#023e4eff", fontWeight: '400', fontSize: 18}}>{"When guests join your waiting list, we will notify you on the following:"}</Text>
                </View>
              </View>

              <View style={[RestaurantCss.rowContainerLF, {paddingTop: 25,backgroundColor:HEXCOLOR.pureWhite}]}>
                <View style={[RestaurantCss.headingLeft, {padding: 10, flexDirection: 'column'}]}>
                  <Text style={{color: '#023e4eff', fontSize: 14, paddingLeft: 0}}>Email Address:</Text>
                  <TextInput
                    style={[RestaurantCss.underlineRectangle, {backgroundColor: '#FFF', color: HEXCOLOR.lightBrown, fontSize: 18, width: 300, height: 60}]}
                    onChangeText={(email) => this.setState({email})}
                    value={this.state.email}
                    keyboardType="email-address"/>
                </View>
                <View style={{marginRight: 8}}>
                  <Switch onValueChange={(value) => this.setIsOnEmailNotification(value)}
                    onTintColor={'#023e4eff'}
                    style={{marginBottom: 10}}
                    thumbColor={HEXCOLOR.pureWhite}
                    tintColor={HEXCOLOR.lightBrown}
                    ios_backgroundColor={HEXCOLOR.lightBrown}
                    value={this.state.isOnEmailNotification}/>
                </View>
              </View>
              <View style={[RestaurantCss.rowContainerLF, {paddingTop: 25, backgroundColor:HEXCOLOR.pureWhite}]}>
                <View style={[RestaurantCss.headingLeft]}>
                  <Text style={{color: '#023e4eff', fontSize: 14, paddingLeft: 10}}>App notification:</Text>
                </View>
                <View style={[RestaurantCss.headingRight]}>
                  <Switch onValueChange={(value) => this.setIsOnAppNotification(value)}
                    onTintColor={'#023e4eff'}
                    style={{marginBottom: 10}}
                    thumbColor={HEXCOLOR.pureWhite}
                    tintColor={HEXCOLOR.lightBrown}
                    ios_backgroundColor={HEXCOLOR.lightBrown}
                    value={this.state.isOnAppNotification}/>
                </View>
              </View>
              <View style={[RestaurantCss.headingLeft, {padding: 10}]}>
                <Text style={{color: '#023e4eff', fontWeight: '400', fontSize: 16}}>{"Note: For us to notify you via app-notification, you have to be logged in as restaurant in the app."}</Text>
              </View>
              {/*<View style={[RestaurantCss.rowContainerLF, {paddingTop: 25,backgroundColor:HEXCOLOR.pureWhite}]}>
                <View style={[RestaurantCss.headingLeft]}>
                  <Text style={{color: '#023e4eff', fontSize: 14, paddingLeft: 10}}>Fully booked</Text>
                </View>
                <View style={[RestaurantCss.headingRight]}>
                  <Switch onValueChange={(value) => this.setFullyBooked(value)}
                    onTintColor={'#023e4eff'}
                    style={{marginBottom: 10}}
                    thumbTintColor={HEXCOLOR.pureWhite}
                    tintColor={HEXCOLOR.lightBrown}
                    value={this.state.isFullyBooked}/>
                </View>
              </View>*/}
            </View>
          </View>
        );
      }
    }else if(this.state.screenNo === 3){
      if(!this.state.isOnline){
        return (
          <View style={[RestaurantCss.container, {padding: 10}]}>
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
              <Text style={[RestaurantCss.headerText, {color: '#023e4eff'}]}>We can’t seem to connect to the First Served network. Please check your internet connection.</Text>
            </View>
          </View>
        );
      }else{
        return (
          <View style={[RestaurantCss.container, {padding:20, paddingTop:0}]}>
            <View style={{flex: 1, backgroundColor: HEXCOLOR.pureWhite}}>
              <View style={RestaurantCss.navBar}>
                <View style={{...RestaurantCss.leftContainer, paddingLeft: 15}}>
                  <TouchableOpacity
                    onPress={() => this.setState({screenNo: 1})}>
                    <View>
                      <Icon
                        size={21}
                        name='chevron-left'
                        color='white'/>
                    </View>
                  </TouchableOpacity>
                </View>
                <Text style={[RestaurantCss.navtext, {fontWeight:'bold',marginLeft:30,fontSize: 13,marginTop:(Platform.OS === 'ios') ? 5 : 0, color: 'white'}]}>{this.state.restaurant.name ? this.state.restaurant.name.toUpperCase() : ""}</Text>
                <View style={[RestaurantCss.rightContainer, {paddingRight: 15}]}>
                </View>
              </View>

              <View style={[RestaurantCss.rowContainerHCenter, {marginTop: 50}]}>
                <Text style={{fontSize:13,color: '#023e4eff'}}>Table available for :</Text>
              </View>
              <View style={[RestaurantCss.rowContainerHCenter, {marginTop: 20}]}>
                <View style={[RestaurantCss.rectangle, {width: 210, height: 38, flexDirection:'row'}]}>
                  <TouchableOpacity
                    onPress={() => this.minus()}>
                    <View style={[RestaurantCss.rectangle, {width: 55, height: 45, flexDirection:'row'}]}>
                      <Text style={{marginLeft:12,marginBottom:7,color: '#023e4eff', fontSize: 44}}>-</Text>
                    </View>
                  </TouchableOpacity>
                  <View style={[RestaurantCss.rectangle, {borderLeftWidth:0,width: 125, height: 45, flexDirection:'row',borderRightWidth:0}]}>
                    <Text style={{color: '#023e4eff'}}>{this.state.people} people</Text>
                  </View>
                  <TouchableOpacity
                    onPress={() => this.plus()}>
                    <View style={[RestaurantCss.rectangle, {width: 55, height: 45, flexDirection:'row'}]}>
                      <Text style={{marginBottom:4,marginRight:14,color: '#023e4eff', fontSize: 24}}>+</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={[RestaurantCss.rowContainerHCenter, {marginTop: 48}]}>
                <Text style={{fontSize:13,color: '#023e4eff'}}>Table is available from:</Text>
              </View>
              <View style={[RestaurantCss.rowContainerHCenter, {marginTop: 20}]}>
                <View style={[RestaurantCss.rectangle, {width: 210, height: 38, flexDirection:'row', borderColor: '#023e4eff'}]}>
                  <TextInputMask
                    style={{color: '#023e4eff', flex: 1,  height: 38, textAlign: 'center',fontSize:13}}
                    ref={'pStartTime'} type={'datetime'} keyboardType={'default'}
                    onChangeText={startTime => this._handleDatePicked(1, startTime)}
                    value={this.state.txtStartTime} placeholder={'HH:MM'}
                    options={{format: 'HH:mm'}} />
                </View>
              </View>
              <View style={[{flex:0.3,flexDirection:'row',justifyContent:'center', alignItems:'center', marginTop: 52}]}>
                <Text style={{width:230,fontSize:13,color: '#023e4eff',textAlign:'center'}}>The latest the guest should leave the table:</Text>
              </View>
              <View style={[RestaurantCss.rowContainerHCenter, {marginTop: 20}]}>
                <View style={[RestaurantCss.rectangle, {width: 210, height: 38, flexDirection:'row', borderColor: '#023e4eff'}]}>
                  <TextInputMask
                    style={{color: '#023e4eff', flex: 1,  height: 38, textAlign: 'center',fontSize:13}}
                    ref={'pEndTime'} type={'datetime'} keyboardType={'default'}
                    onChangeText={endTime => this._handleDatePicked(2, endTime)}
                    value={this.state.txtEndTime} placeholder={'HH:MM'}
                    options={{format: 'HH:mm'}} />
                </View>
              </View>
              <View style={{flexDirection:'row',justifyContent:'center', marginTop: 50}}>
                <View style={{width: 364, alignItems: 'flex-end'}}>
                  <TouchableOpacity
                    style={[RestaurantCss.publish, {width: 155, backgroundColor:'#023e4eff', borderRadius:0}]}
                    onPress={() => this.setState({screenNo: 1})}>
                      <Text style={[RestaurantCss.publishText,{color:'white'}]}>Cancel</Text>
                  </TouchableOpacity>
                </View>
                <View style={{width: 364}}>
                  <TouchableOpacity
                    style={[RestaurantCss.publish, {alignItems:'center',width:155,backgroundColor:'#023e4eff',borderRadius:0}]}
                    onPress={this.enableBookingOptions(1)}>
                      <Text style={[RestaurantCss.publishText,{color:'white'}]}>Publish</Text>
                  </TouchableOpacity>
                  {/* {<View style={{flex: 1, flexDirection: 'row', marginLeft: 60, marginTop: 10}}>
                    <Text style={{fontWeight: 'bold'}}>Note: </Text>
                    <Text style={{width: 260,fontSize: 13,color: '#023e4eff'}}>
                      The table has to be available in your booking system for the guest to book it.
                    </Text>
                  </View>} */}
                </View>
              </View>
            </View>
            {isBookingOptionsModalVisible && this.renderBookingOptions()}
          </View>
        );
      }
    }
  }

  renderBookingOptions = () => {
    const {
      byPhone,
      byWalkIn,
      byBookingSystem,
      isBookingOptionsModalVisible,
      isBookingOptionsModalVisibleForLunch,
      isBookingOptionsModalVisibleForDinner,
    } = this.state;

    return (
      <Modal
        animationType="slide"
        transparent={false}
        visible={
          isBookingOptionsModalVisible ||
          isBookingOptionsModalVisibleForLunch ||
          isBookingOptionsModalVisibleForDinner
        }
        onRequestClose={() => this.setState({
          isBookingOptionsModalVisible: false,
          isBookingOptionsModalVisibleForLunch: false,
          isBookingOptionsModalVisibleForDinner: false
        })}
      >
        <View style={[RestaurantCss.container, {paddingBottom: 0}]}>
          <View style={RestaurantCss.navBar}>
            <View style={RestaurantCss.leftContainer}></View>
            <Text style={[RestaurantCss.navtext, {fontWeight:'bold',marginLeft:30,fontSize: 13,marginTop:(Platform.OS === 'ios') ? 5 : 0, color: 'white'}]}>{"Publish Table".toUpperCase()}</Text>
            <View style={[RestaurantCss.rightContainer, {paddingRight: 15}]}>
              <TouchableOpacity
                onPress={() => {
                  if (isBookingOptionsModalVisibleForDinner) this.setIsOpenTableForDinner(false);
                  if (isBookingOptionsModalVisibleForLunch) this.setIsOpenTableForLunch(false);

                  this.setState({
                    isBookingOptionsModalVisible: false,
                    isBookingOptionsModalVisibleForLunch: false,
                    isBookingOptionsModalVisibleForDinner: false
                  });
                }}>
                <View>
                  <IIcon
                    size={42}
                    name='ios-close'
                    color='white'/>
                </View>
              </TouchableOpacity>
            </View>
          </View>

          <View style={styles.container}>
            <Text style={styles.mainHeading}>
              {"HOW CAN THE GUEST\nBOOK THE TABLE?"}
            </Text>

            <View style={styles.checkboxWrapper}>
              <CheckBox
                title={"In booking system"}
                disabled={byWalkIn}
                activeOpacity={byWalkIn ? 0 : 1}
                checked={byBookingSystem}
                onPress={() => this.setState({ byBookingSystem: !byBookingSystem })}
                containerStyle={styles.checkboxContainer}
              />

              <CheckBox
                title={"By phone"}
                disabled={byWalkIn}
                activeOpacity={byWalkIn ? 0 : 1}
                checked={byPhone}
                onPress={() => this.setState({ byPhone: !byPhone })}
                containerStyle={styles.checkboxContainer}
              />

              <CheckBox
                title={"Only walk-ins"}
                checked={byWalkIn}
                onPress={() => this.setState({ byWalkIn: !byWalkIn, byPhone: false, byBookingSystem: false })}
                containerStyle={styles.checkboxContainer}
              />
            </View>
            
            {this.state.bookingOptionsError ? <View style={styles.errorWrapper}>
                <Text style={styles.errorText}>{"Please select at least one of the options"}</Text>
              </View>
              :
              null}

            <View style={styles.buttonWrapper}>
              <TouchableOpacity
                style={[RestaurantCss.publish, styles.button]}
                onPress={this.publishTable}>
                  <Text style={[RestaurantCss.publishText,{color:'white'}]}>{"DONE"}</Text>
              </TouchableOpacity>
            </View>
          </View>

        </View>
      </Modal>
    )
  }

  deleteTable = (tableKey) => {
    const th = this;
    Popup.create({
      title: "Table",
      content: 'Are you sure you want to delete this table?',
      buttons: {
        right: [{
          text: 'Delete',
          className: 'danger',
          action: function () {
            Database.deleteTable(tableKey, th.state.restaurantKey, function(res){
              if(res === 'error'){
                Popup.alert('Unable to delete table. Please try again.');
              }else{
                console.log("Delete table", "Table deleted.");
              }
            });

            Popup.close();
          }
        }],
        left: [{
          text: 'Cancel',
          action: function () {
            Popup.close();
          }
        }]
      }
    });   
  }

  toogleNamePhone = (tableKey, isBooked) => {
    if(!isBooked) return;
    var bTables = this.state.bookedTables;

    for (var i = 0; i < bTables.length; i++) {
      var tbl = bTables[i];
      if(tbl.key === tableKey){
        tbl.shouldShowName = tbl.shouldShowName < 3 ? tbl.shouldShowName + 1 : 1;
        break;
      }
    }

    this.setState({
      bookedTables: bTables,
    });
  }

  _renderItem = ({item: tableType}) => {
    return (
      <TableTypeListItem tableType={tableType}/>
    );
  }

  renderTable = ({item: table}) => {
    return (
      <TableListItem
        table={table}
        toogleNamePhone={this.toogleNamePhone}
        deleteTable={this.deleteTable.bind(this)}
        isBooked={false}
      />
    )
  }

  renderBookedTable = ({item: table}) => {
    return (
      <TableListItem
        table={table}
        toogleNamePhone={this.toogleNamePhone}
        deleteTable={this.deleteTable.bind(this)}
        isBooked={true}
      />
    )
  }

  setFullyBooked = (isFullyBooked) => {
    if(this.state.restaurantKey !== ''){
      Database.setRestaurantFullyBooked(this.state.userId, this.state.restaurantKey, isFullyBooked);
      this.setState({isFullyBooked: isFullyBooked});
    }
  }

  setIsOnEmailNotification(isOnEmailNotification) {
    if(this.state.restaurantKey !== ''){
      Database.setIsOnEmailNotification(this.state.userId, this.state.restaurantKey, isOnEmailNotification, this.state.email);
      this.setState({isOnEmailNotification: isOnEmailNotification});
    }
  }

  setIsOpenTableForDinner = (isOpenTable) => {
    if(this.state.restaurantKey !== ''){
      Database.setIsOpenTableForDinner(this.state.userId, this.state.restaurantKey, isOpenTable);
      if (isOpenTable) this.enableBookingOptions(3)();
    }
  }

  setIsOpenTableForLunch = (isOpenTable) => {
    if(this.state.restaurantKey !== ''){
      Database.setIsOpenTableForLunch(this.state.userId, this.state.restaurantKey, isOpenTable);
      if (isOpenTable) this.enableBookingOptions(2)();
    }
  }

  setIsOnAppNotification(isOnAppNotification) {
    if(this.state.restaurantKey !== ''){
      Database.setIsOnAppNotification(this.state.userId, this.state.restaurantKey, isOnAppNotification);
      this.setState({isOnAppNotification: isOnAppNotification});
    }
  }

  plus = () => {
    this.setState({people: (this.state.people + 1)});
  }

  minus = () => {
    if(this.state.people > 1) this.setState({people: this.state.people - 1});
  }

  enableBookingOptions = (bookingOptionFor) => () => {
    let {
      waitingTables,
      txtStartTime,
      txtEndTime,
      startTime,
      endTime,
      people
    } = {...this.state};

    if (bookingOptionFor === 1) {
      var movStartTime = Moment(txtStartTime, "HH:mm", true);
      var movEndTime = Moment(txtEndTime, "HH:mm", true);

      let waitingCount = 0;
      waitingTables.forEach(waitingTable => {
        var uEndTime = waitingTable.endTime;
        var uStartTime = waitingTable.startTime;
        var tDiff = uEndTime - uStartTime;
        var curDate = new Date();
        if (uEndTime && uEndTime !== 'SET END TIME') {
          uEndTime = new Date(uEndTime);
          uEndTime.setDate(curDate.getDate());
          uEndTime.setMonth(curDate.getMonth());
          uEndTime.setFullYear(curDate.getFullYear());
          uEndTime.setSeconds(0);
          uEndTime = uEndTime.getTime();
        }
        uStartTime = uEndTime - tDiff;

        uStartTime = new Date(uStartTime);
        uStartTime.setSeconds(0);
        uStartTime = uStartTime.getTime();
        startTime = new Date(startTime);
        startTime.setSeconds(0);
        startTime = startTime.getTime();
        endTime = new Date(endTime);
        endTime.setSeconds(0);
        endTime = endTime.getTime();

        var chk = true;
        if (uEndTime && uStartTime && (endTime < uStartTime || startTime > uEndTime)) chk = false;
        if (waitingTable.tableType !== people) chk = false;
        if (chk) waitingCount++;

        console.log("uStartTime: ", new Date(uStartTime), " uEndTime: ", new Date(uEndTime), " startTime: ", new Date(startTime), " endTime: ", new Date(endTime), " waitingCount: ", waitingCount)
      });

      if (startTime === 'Set start time' || !movStartTime.isValid()){
        this.setState({isStartTimeValid: false});
        alert("Start time is invalid.");
        return;
      }
      if (endTime === 'Set end time' || !movEndTime.isValid()) {
        this.setState({isEndTimeValid: false});
        alert("End time is invalid.");
        return;
      }
      if (startTime >= endTime) {
        alert("Start time should be less than end time.");
        return;
      }

      if (waitingCount === 0) {
        alert('No guest is currently on the waiting list with these search criterias.');

        return;
      }

      this.setState({
        isBookingOptionsModalVisible: true,
        bookingOptionsError: false,
        byBookingSystem: false,
        byPhone: false,
        byWalkIn: false,
      });
    } else if (bookingOptionFor === 2) {
      this.setState({
        isBookingOptionsModalVisibleForLunch: true,
        isOpenTableForLunch: true,
        bookingOptionsError: false,
        byBookingSystem: false,
        byPhone: false,
        byWalkIn: false,
      });
    } else if (bookingOptionFor === 3) {
      setTimeout(() => this.setState({
        isBookingOptionsModalVisibleForDinner: true,
        isOpenTableForDinner: true,
        bookingOptionsError: false,
        byBookingSystem: false,
        byPhone: false,
        byWalkIn: false,
      }), 1000);
    }
  }

  publishTable = () => {
    const {
      userId,
      people,
      startTime,
      endTime,
      restaurantKey,
      byBookingSystem,
      byPhone,
      byWalkIn,
      restaurant,
      isBookingOptionsModalVisible,
      isBookingOptionsModalVisibleForDinner,
      isBookingOptionsModalVisibleForLunch
    } = this.state;

    if (!byBookingSystem && !byPhone && !byWalkIn){
      this.setState({bookingOptionsError: true})
    } else if (isBookingOptionsModalVisible) {
      let bookingOptions = [];
      if (byBookingSystem)
        bookingOptions.push("online-booking");
      
      if (byPhone)
        bookingOptions.push("phone")

      if (byWalkIn)
        bookingOptions.push("walk-ins")

      var table = {
        restAdmin: userId,
        restaurantKey: restaurantKey,
        restaurantName: restaurant.name,
        pax: people,
        startTime: startTime,
        endTime: endTime,
        isBooked: false,
        searchKey: restaurantKey + "_0",
        bookingOptions: bookingOptions
      };

      const th = this;

      Database.publishTable(table, function(res){
        console.log('Publish table', res);
        th.setState({screenNo: 1, isBookingOptionsModalVisible: false});
      });
    } else if (isBookingOptionsModalVisibleForDinner) {
      Database.openTable(userId, restaurantKey, {
        byBookingSystemForDinner: byBookingSystem,
        byPhoneForDinner: byPhone,
        byWalkInForDinner: byWalkIn,
        openTableEnableTimeForDinner: new Date().getTime()
      });
      this.setState({
        isBookingOptionsModalVisibleForDinner: false,
      });
    } else if (isBookingOptionsModalVisibleForLunch) {
      Database.openTable(userId, restaurantKey, {
        byBookingSystemForLunch: byBookingSystem,
        byPhoneForLunch: byPhone,
        byWalkInForLunch: byWalkIn,
        openTableEnableTimeForLunch: new Date().getTime()
      });
      this.setState({
        isBookingOptionsModalVisibleForLunch: false
      });
    }
  }

  _handleDatePicked = (timePickerFor, val) => {
    var date = Moment(val, "HH:mm", true);
    console.log('A date has been picked: ', date._d);

    if(date.isValid()){
      if(timePickerFor === 1){
        var idealEndTime = Moment(date._d).add(2, 'hours')
        this.setState({txtStartTime: val, startTime: date._d.getTime(), txtEndTime: Moment(idealEndTime._d).format('HH:mm'), endTime: idealEndTime._d.getTime() });
      }else if(timePickerFor === 2){
        this.setState({txtEndTime: val, endTime: date._d.getTime()});
      }
    }else{
      if(timePickerFor === 1){
        this.setState({txtStartTime: val, startTime: 'Set start time', endTime: 'Set end time'});
      }else if(timePickerFor === 2){
        this.setState({txtEndTime: val, endTime: 'Set end time'});
      }
    }
  };
}

const styles = StyleSheet.create({
  formGroup: {
    paddingTop: 50,
    width: 900
  },
  title: {
    paddingBottom: 16,
    textAlign: "center",
    color: HEXCOLOR.black,
    fontSize: 35,
    fontWeight: "bold",
    opacity: 0.8,
  },
  submit: {
    paddingTop: 30
  },
  response: {
    textAlign: "center",
    paddingTop: 0,
    padding: 50
  },
  container: {
    flex: 1,
    paddingVertical: 50,
    paddingHorizontal: 50,
  },
  mainHeading: {
    color: HEXCOLOR.blueColor, 
    fontSize: 16, 
    fontWeight: 'bold',
    textAlign:'center', 
  },
  checkboxWrapper: {
    marginTop: 30,
    paddingLeft: (screenWidth - 320) / 2
  },  
  checkboxContainer: {
    backgroundColor: 'transparent', 
    borderWidth: 0,
    marginTop: 10
  },
  buttonWrapper: {
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    marginTop: 30
  },
  button: {
    alignItems:'center',
    width:155,
    backgroundColor:'#023e4eff',
    borderRadius:0
  },
  errorWrapper: {
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    marginTop: 5
  },
  errorText: {
    fontSize:13,
    color: 'red',
    textAlign:'center'
  }
});

export default EmailLogin;
