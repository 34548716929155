export const HEXCOLOR = {
  whiteColor: '#F1F0EC',
  whiteSmoke: 'whitesmoke',
  skyblue:'#68a0cf',
  pureWhite:'#fff',
  lightGrey:'#626262',
  black:'#000',
  lightBrown:'#98866F',
  endeavour: '#023e4eff',
  blueBerry: '#7873B1',
};
